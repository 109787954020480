import { useContext, useEffect, useState } from 'react';
import BackendTable from 'react-backend-table';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';
import Badge from 'react-bootstrap/Badge';
import ExpirationDateModify from './ExpirationDateModify';
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function VehicleRoutePermitList(props) {
    const { jwt } = useContext(userPermissionContext);
    const [renewModalShow, setRenewModalShow] = useState(false);
    const [idVmsVehicleInfo, setIdVmsVehicleInfo] = useState('');
    const [type, setType] = useState('');
    const [prevUpdateDate, setPrevUpdateDate] = useState('');

    let columns = [
        { title: 'Id', field: 'idVmsVehicleInfo', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
        {
            title: 'Vehicle Number', field: 'vehicleNumber', sortable: true, searchable: true,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (
                    <Link to={`/vehicleDetails/${rowValue.idVmsVehicleInfo}`} target="_blank" rel="noopener noreferrer">
                        {rowValue.vehicleNumber}
                    </Link>
                )

            })
        },
        // { title: 'Vehicle Owner', field: 'vehicleOwner', sortable: true, searchable: true },
        { title: 'Fixed Asset', field: 'faCode', sortable: true, searchable: true },
        // { title: 'Vendor', field: 'vendorName', searchable: true, sortable: true },
        { title: 'Vehicle Type', field: 'vehicleType', searchable: true, sortable: true },
        { title: 'Current Project', field: 'assignedProject', searchable: true, sortable: true },
        {   title: 'Assigned User', field: 'assignedUser', searchable: true, sortable: true},
        {  title: 'Assigned Driver', field: 'assignedDriver', searchable: true, sortable: true},
        { title: 'Route Permit Expiration Date', field: 'routePermitExpirationDate', searchable: true, sortable: true },
        {
            title: 'Status',
            field: 'daysLeft',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (
                    <>
                        {rowValue.daysLeft <= 7 && <Badge bg="danger">{rowValue.daysLeft} Days</Badge>}
                        {rowValue.daysLeft > 7 && rowValue.daysLeft <= 30 && <Badge bg="warning">{rowValue.daysLeft} Days</Badge>}
                        {rowValue.daysLeft > 30 && rowValue.daysLeft <= 180 && <Badge bg="primary">{rowValue.daysLeft} Days</Badge>}
                        {rowValue.daysLeft > 180 && <Badge bg="info">{rowValue.daysLeft} Days</Badge>}
                    </>
                )
            })
        },
        {
            title: 'Action',
            field: 'action',
            searchable: false,
            sortable: false,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (
                    <>
                        <Button onClick={() => confirmRenew(rowValue.idVmsVehicleInfo, 'route_permit', rowValue.prevUpdateDate)} variant="primary" size="sm">Renew</Button>
                    </>
                )
            })
        }
    ]
    const confirmRenew = (idVmsVehicleInfo, type, prevUpdateDate) => {
        setRenewModalShow(true);
        setIdVmsVehicleInfo(idVmsVehicleInfo);
        setType(type);
        setPrevUpdateDate(prevUpdateDate);
    }

    const onRenewConfirm = () => {
        setRenewModalShow(false);
        options.reloadMyTable();
    }

    let renewModalClose = () => {
        setRenewModalShow(false);
    };

    let options = {
        url: process.env.REACT_APP_API_URL + 'vehicleRoutePermitList',
        authorization: 'Bearer ' + jwt.token,
        perPage: [20, 50, 100, 500, 'All'],
        orderBy: 'daysLeft',
        orderType: 'asc',
        columnSearch: true,
        extraData: {
            range: props.range,
            vehicleType: props.vehicleType
        }
    }

    useEffect(() => {
        options.reloadMyTable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.range, props.vehicleType])

    return (
        <>
            <div className='card card-custom'>
                <div className='ml-12'>
                    <BackendTable columns={columns} options={options} />
                </div>
            </div>
            <Modal size="lg" show={renewModalShow} onHide={renewModalClose} aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Body>
                    <ExpirationDateModify onRenewConfirm={onRenewConfirm} idVmsVehicleInfo={idVmsVehicleInfo} type={type} prevUpdateDate={prevUpdateDate} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="outline-primary" size="sm" onClick={renewModalClose}>Ok</Button>
                    <Button variant="outline-secondary" size="sm" onClick={renewModalClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default VehicleRoutePermitList;